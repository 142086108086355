import {EnvConfig, FullConfig} from "./types";

const prodConfig: EnvConfig = {
  baseUrl: 'https://offsetspot-prod.herokuapp.com',
  cookieConfig:  { path: '/' }
};

const testConfig: EnvConfig = {
  baseUrl: 'https://offsetspot-test.herokuapp.com',
  cookieConfig:  { path: '/' }
};

const localConfig: EnvConfig = {
  baseUrl: 'https://offsetspot-test.herokuapp.com',
  cookieConfig: { path: '/' }
};

const resolveConfig = (hostname: string): EnvConfig => {
  if (hostname.includes('localhost')) {
    return localConfig;
  } else if (hostname.includes("test")) {
    return testConfig;
  } else {
    return prodConfig;
  }
};

const regionSpecificConfig: EnvConfig = resolveConfig(window.location.hostname);
const fullConfig: FullConfig = Object.assign(regionSpecificConfig, {
  trackingCode: 'UA-96533413-6',
  tokenCookieName: 'userToken'
});

export default fullConfig;
