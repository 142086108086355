
import { camelCase } from 'lodash';

const service = {
  camelizeKeys(object: any): any {
    if (Array.isArray(object)) {
      return object.map(v => this.camelizeKeys(v));
    } else if (object !== null && object !== undefined && object.constructor === Object) {
      return Object.keys(object).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys((object[key] as any)),
        }),
        {},
      );
    }
    return object;
  }
}

export const camelizeKeys = (value: any) => service.camelizeKeys(value);
export default service;

