
import { storeBlogs } from '../store';
import { dispatch } from '../store';

export default {
  onAppStart() {
    this.fetchBlogPosts();
  },

  fetchBlogPosts() {
    const blogTemplate =  {
      path: 'blog-name',
      title: 'Blog Title',
      description: 'Abilities or he perfectly pretended so strangers be exquisite. Oh to another chamber pleased imagine do in. Went me rank at last loud shot an draw. Excellent so to no sincerity smallness. Removal request delight if on he we. Unaffected in we by apartments astonished to decisively themselves. Offended ten old consider speaking. ',
      image: 'https://static.offsetspot.com/images/project_1_1.jpg',
      body: ''
    };

    const blogs: any[] = [];

    for(let i = 0; i < 3; i++) {
      blogs.push(blogTemplate);
    }

    dispatch(storeBlogs(blogs));
  }
};

