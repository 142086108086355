import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import calculator from './reducers/calculator';
import register from './reducers/register';
import projects from './reducers/projects';
import user from './reducers/user';
import blog from './reducers/blog';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  calculator,
  register,
  projects,
  user,
  blog
});

const initialState = {
  calculator: {
    steps: [],
    inputs: {},
    output: {
      analysis: {}
    }
  },
  register: {
    subscription: null,
    employer: null
  },
  projects: {
    items: [],
    quotes: [],
    subscriptions: []
  },
  user: {
    token: null,
    profile: null,
    statistics: null,
    employer: null,
    subscription: null,
    footprint: null,
    invoice: {
      next: null,
      past: []
    }
  },
  blog: {
    posts: []
  }
};

export const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunk)));
export const dispatch = store.dispatch;

export * from './actions/calculator';
export * from './actions/register';
export * from './actions/projects';
export * from './actions/user';
export * from './actions/blog';
