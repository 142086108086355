
import { setCalculatorConfig, setCalculatorOutput } from '../store';
import { dispatch } from '../store';
import { CalculatorConfigResponse } from '../types';
import axios from 'axios';
import config from '../config';

export default {
  fetchCalculatorConfig() {
    const handleResponse = (response: CalculatorConfigResponse) => {
      const { error, config } = response;
      if (error === 0 && config !== null) {
        dispatch(setCalculatorConfig(config));
      } else {
        // Handle generic error
      }
    };

    axios.get(`${config.baseUrl}/calculator/get_config`)
      .then(resp => handleResponse(resp.data))
      .catch(() => handleResponse({ error: -1, config: null }))
  },

  calculateFootprint(inputs: any, employer: any) {
    const body = {
      carbon_usage: inputs,
      employer_subscribe_code: employer ? employer.subscribeCode : null
    };

    // TODO: Update backend so it can handle this being null
    if (body.employer_subscribe_code === null) {
      delete body['employer_subscribe_code'];
    }

    axios.post(`${config.baseUrl}/calculator/calculate`, body)
      .then(res => dispatch(setCalculatorOutput(res.data)))
      .catch(err => console.error(err))
  }
};

