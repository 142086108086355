
import notificationService from './toast';
import { WithSnackbarProps } from 'notistack';
import { LogonResponse, LogonBody, VerifyBody, VerifyResponse, VerifyStatus } from '../types';
import config from '../config';
import * as Actions from '../store';
import cookie from 'react-cookies'
import axios from 'axios';
import { dispatch } from "../store";

export default {
  toast: notificationService,

  onAppStart(history: any) {
    const token = cookie.load(config.tokenCookieName);
    if (token !== undefined) {
      dispatch(Actions.storeToken(token, () => null));
    }
  },

  login(props: WithSnackbarProps, body: LogonBody, callback: (verified: boolean, token?: string) => void) {
    const handleResponse = (response: LogonResponse) => {
      if (response.error === 0 && response.auth_token) {
        this.toast.showSuccess(props, 'Login Successful!');
        callback(true, response.auth_token);
      } else if (response.error === 4) {
        callback(false, undefined)
      } else {
        this.toast.showError(props, 'Login Failed');
      }
    };

    axios.post(`${config.baseUrl}/user/login`, body)
      .then(resp => handleResponse(resp.data))
      .catch(() => handleResponse({ error: -1 }))
  },

  logout() {
    cookie.remove(config.tokenCookieName);
  },

  verifyEmailAddress(verifyCode: string, callback: (result: VerifyStatus, token: string | null) => void) {
    const body: VerifyBody = { email_token: verifyCode };
    const handleResponse = (response: VerifyResponse) => {
      callback(response.error, response.auth_token);
    };

    axios.post(`${config.baseUrl}/user/verify_email`, body)
      .then(resp => handleResponse(resp.data))
      .catch(() => handleResponse({ error: -1, auth_token: null }))
  },

  sendResetEmail(emailAddress: string, callback: () => void) {
    const body = { email: emailAddress };
    axios.post(`${config.baseUrl}/user/forgotten_password_reset_request`, body)
      .then(resp => callback())
      .catch(() => callback())
  },

  checkResetToken(token: string, callback: (okay: boolean) => void) {
    const body = { password_reset_token: token };
    axios.post(`${config.baseUrl}/user/forgotten_password_check_token`, body)
      .then(resp => callback(resp.data.error === 0))
      .catch(() => callback(false))
  },

  changePassword(token: string, password: string, callback: (token: string | null) => void) {
    const body = { password_reset_token: token, new_password: password };
    axios.post(`${config.baseUrl}/user/forgotten_password_reset`, body)
      .then(resp => callback(resp.data.auth_token))
      .catch(() => callback(null))
  },

  fetchUserData(token: string, dispatch: any, success: () => void) {
    const options = { headers: { 'auth_token': token } };
    const userRequest = [
      axios.get(`${config.baseUrl}/stats/user_dashboard_stats`, options),
      axios.get(`${config.baseUrl}/user/profile`, options),
      axios.get(`${config.baseUrl}/invoice/all_invoices`, options),
      axios.get(`${config.baseUrl}/invoice/next_invoice`, options),
      axios.get(`${config.baseUrl}/footprint/get_current_footprint`, options),
      axios.get(`${config.baseUrl}/subscription/current_subscription`, options)
    ];

    axios.all(userRequest).then(axios.spread((...responses) => {
      if (responses.every((response: any) => response.data.error === 0)) {
        dispatch(Actions.storeStatistics(responses[0].data));
        dispatch(Actions.storeProfile(responses[1].data));
        dispatch(Actions.storeInvoices(responses[2].data));
        dispatch(Actions.storeNextInvoice(responses[3].data));
        dispatch(Actions.storeFootprint(responses[4].data));
        dispatch(Actions.storeSubscription(responses[5].data));

        cookie.save(config.tokenCookieName, token, config.cookieConfig);
        success();
      } else {
        console.error('One or more responses returned an error', responses);
        dispatch(Actions.clearToken());
      }
    })).catch(errors => {
      console.error(errors);
      dispatch(Actions.clearToken());
    })
  }
}
