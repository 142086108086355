

import { Route, Redirect, RouteProps } from "react-router-dom";
import React from 'react';

export type PrivateRouteProps = RouteProps & {
  component: React.ComponentType;
  authorised: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const Component = props.component;
  const { component, authorised, ...rest } = props;
  return (
    <Route {...rest} render={(props: any) =>
      authorised === true ?
        <Component {...props} /> :
        <Redirect to='/' />} />
  )
}
