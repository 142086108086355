
import CalculatorService from '../../services/calculator';
import ProjectService from "../../services/project";

export const SET_CALCULATION_CONFIG = "SET_CALCULATION_CONFIG";
export const SET_CALCULATOR_FIELD_UNIT = "SET_CALCULATOR_FIELD_UNIT";
export const SET_CALCULATOR_FIELD_VALUE = "SET_CALCULATOR_FIELD_VALUE";
export const SET_INPUTS = "SET_INPUTS";
export const SET_OUTPUT = "SET_OUTPUT";

export function setCalculatorConfig(config: any): any {
  return calculateFootprint({
    type: SET_CALCULATION_CONFIG,
    data: config
  });
}

export function setCalculatorOutput(output: any): any {
  return (dispatch: any, getState: any) => {
    dispatch({ type: SET_OUTPUT, data: output });

    const { projects, register } = getState();
    const projectIds = projects.items.map((proj: any) => proj.projectUid);
    const employer = register.employer ? register.employer.subscribeCode : null;
    ProjectService.fetchProjectQuotes(projectIds, employer, output.tonnes);
  };
}

export function setCalculatorInput(inputs: any) {
  return {
    type: SET_INPUTS,
    data: inputs
  };
}

export function setCalculatorFieldValue(name: string, value: any) {
  return {
    type: SET_CALCULATOR_FIELD_VALUE,
    data: { name, value }
  }
}

export function setCalculatorFieldUnit(name: string, unit: string, value: any) {
  return {
    type: SET_CALCULATOR_FIELD_UNIT,
    data: { name, unit, value }
  }
}

export function calculateFootprint(action?: any): any {
  return (dispatch: any, getState: any) => {
    if (action) {
      dispatch(action);
    }

    const { calculator, register } = getState();
    CalculatorService.calculateFootprint(calculator.inputs, register.employer);
  }
}
