
import { Action } from '../../types';
import * as Actions from '../actions/projects';
import { camelizeKeys } from '../../services/camelcase';

const projects = (state: any = {}, action: Action) => {
  switch (action.type) {
    case Actions.STORE_PROJECTS: {
      const projects = camelizeKeys(action.data);
      projects.sort((p1: any, p2: any) => p1.sortOrder - p2.sortOrder);
      return Object.assign({}, state, { items: projects });
    }
    case Actions.STORE_QUOTES: {
      const quotes = camelizeKeys(action.data);
      const projects = state.items.reduce((obj: any, cur: any, i: any) => {
        obj[cur.projectUid] = cur;
        return obj;
      }, {});

      const subscriptions = Object.keys(quotes).map(id => Object.assign({}, projects[id], { cost: quotes[id] }));
      subscriptions.sort((p1: any, p2: any) => p1.sortOrder - p2.sortOrder);
      return Object.assign({}, state, { quotes, subscriptions })
    }
  }

  return state;
};

export default projects;
