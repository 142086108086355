
import React from 'react';
import { Helmet } from "react-helmet";
import { createLazyRoute } from "./LazyRoute";

export type HelmetRouteArgs = {
  title: string;
  description: string;
  url: string;
  loader: any;
}

export const createHelmetRoute = (props: HelmetRouteArgs) => {
  const LazyRoute = createLazyRoute(props.loader);

  return () => {
    return (
      <React.Fragment>
        <Helmet>
          <title>{props.title}</title>
          <meta name="name" content={props.title} />
          <meta name="description" content={props.description} />
          <link rel="canonical" href={props.url} />
        </Helmet>
        <LazyRoute />
      </React.Fragment>
    )
  }
};
