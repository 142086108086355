import React, { Component } from 'react';
import { Router } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import { createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import { ScrollToTop } from './components/ScrollTop';
import Navbar  from './components/navigation/Navbar';
import CalculatorService from './services/calculator';
import CamelcaseService from './services/camelcase';
import SubscribeService from './services/subscribe';
import ContactService from './services/contact';
import EmployerService from './services/employer';
import ProjectService from './services/project';
import ToastService from './services/toast';
import UserService from './services/user';
import BlogService from './services/blog';
import { Service } from './types';
import './styles/styles.scss';

const maxSnacks = 1;
const theme = createMuiTheme({
  typography: {
    fontFamily: ['Blinker', 'sans-serif'].join(', ')
  },
  palette: {
    primary: {
      main: '#5e48c0'
    },
    secondary: {
      main: '#687bff'
    }
  }
});

const history = createBrowserHistory();
const services: Service[] = [
  CalculatorService,
  CamelcaseService,
  SubscribeService,
  EmployerService,
  ContactService,
  ProjectService,
  ToastService,
  UserService,
  BlogService
];

class App extends Component {
  componentDidMount() {
    services.forEach(service => {
      if (service.onAppStart) {
        service.onAppStart(history);
      }
    });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={maxSnacks}>
          <Router history={history}>
            <GoogleAnalytics />
            <ScrollToTop />
            <Navbar />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
