
import ReactGoogleAnalytics from 'react-ga'
import { withRouter } from "react-router-dom";
import config from '../config';

export const GoogleAnalytics = withRouter(({ history }) => {
  const recordPageView = (location: any) => {
    ReactGoogleAnalytics.set({ page: location.pathname });
    ReactGoogleAnalytics.pageview(location.pathname);
  };

  ReactGoogleAnalytics.initialize(config.trackingCode);
  recordPageView(window.location);
  history.listen((location) => recordPageView(location));
  return null;
});
