

import { storeProjects, storeQuotes } from '../store';
import { ProjectListResponse, QuotesResponse } from '../types';
import { dispatch } from '../store';
import config from '../config';
import axios from 'axios';

export default {
  onAppStart() {
    this.fetchProjectList();
  },

  fetchProjectList() {
    const handleResponse = (response: ProjectListResponse) => {
      const { error, projects } = response;
      if (error === 0 && projects.length > 0) {
        dispatch(storeProjects(projects));
      } else {
        console.error('Unable to get project', error, response)
      }
    };

    axios.get(`${config.baseUrl}/project`)
      .then(resp => handleResponse(resp.data))
      .catch(() => handleResponse({ error: -1, projects: [] }))
  },

  fetchProjectQuotes(projectUids: number[], companyCode: string, tonnes: string) {
    const handleResponse = (response: QuotesResponse) => {
      const { error, project_quotes } = response;
      if (error === 0 && Object.keys(project_quotes).length > 0) {
        dispatch(storeQuotes(project_quotes));
      } else {
        console.error('Unable to get quotes', error, response)
      }
    };

    const body = { projectUids, companyCode, tonnes };
    const snakecaseBody: any = { tonnes: Number(body.tonnes), project_uids: body.projectUids };

    if(body.companyCode) {
      snakecaseBody['employer_subscribe_code'] = body.companyCode;
    }

    axios.post(`${config.baseUrl}/project/get_quotes`, snakecaseBody)
      .then(resp => handleResponse(resp.data))
      .catch(error => {
        console.error(error);
        handleResponse({ error: -1, project_quotes: [] })
      });
  }
}
