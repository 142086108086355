
import { Action } from '../../types';
import * as Actions from '../actions/user';
import { camelizeKeys } from "../../services/camelcase";

const user = (state: any = {}, action: Action) => {
  // Removing error code from any actions (comes web http request)
  if (action && action.data) {
    delete action.data['error'];
  }

  switch(action.type) {
    case Actions.STORE_TOKEN: {
      const newState = Object.assign({}, state);
      newState.token = action.data;
      return newState;
    }
    case Actions.CLEAR_TOKEN: {
      const newState = Object.assign({}, state);
      newState.token = null;
      return newState;
    }
    case Actions.STORE_PROFILE: {
      const newState = Object.assign({}, state);
      newState.profile = camelizeKeys(action.data.user);
      newState.employer = camelizeKeys(action.data.employer);
      return newState;
    }
    case Actions.STORE_STATISTICS: {
      const newState = Object.assign({}, state);
      newState.statistics = camelizeKeys(action.data);
      return newState;
    }
    case Actions.STORE_INVOICES: {
      const newState = Object.assign({}, state);
      newState.invoice.past = camelizeKeys(action.data.invoices);
      return newState;
    }
    case Actions.STORE_NEXT_INVOICE: {
      const newState = Object.assign({}, state);
      newState.invoice.next = camelizeKeys(action.data);
      return newState;
    }
    case Actions.STORE_FOOTPRINT: {
      const newState = Object.assign({}, state);
      newState.footprint = camelizeKeys(action.data);
      newState.footprint.breakdownForAnalysis = action.data.breakdown_for_analysis;
      return newState;
    }
    case Actions.STORE_SUBSCRIPTION: {
      const newState = Object.assign({}, state);
      newState.subscription = camelizeKeys(action.data);
      return newState;
    }
  }

  return state;
};

export default user;
