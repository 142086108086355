
import { Action } from '../../types';
import * as Actions from '../actions/blog';

const blogs = (state: any = {}, action: Action) => {
  switch(action.type) {
    case Actions.STORE_BLOGS: {
      const newState = Object.assign({}, state);
      newState.posts = action.data;
      return newState;
    }
  }

  return state;
};

export default blogs;
