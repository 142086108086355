
import { Project } from '../../types/index';

export const STORE_PROJECTS = "STORE_PROJECTS";
export const STORE_QUOTES = 'STORE_QUOTES';

export const storeProjects = (projects: Project[]) => {
  return { type: STORE_PROJECTS, data: projects }
};

export const storeQuotes = (quotes: any) => {
  return { type: STORE_QUOTES, data: quotes }
};
