
import Loadable from 'react-loadable';

export const createLazyRoute = (callback: any) => {
  return Loadable({
    loader: callback,
    loading: (props: Loadable.LoadingComponentProps) => {
      if (props.error) {
        console.error("Error loading component", props.error);
      }

      // TODO: Discuss loading symbol later
      //if (props.isLoading) {

      //}

      return null;
    }
  })
};
