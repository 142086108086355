import React, { Component } from 'react';
import {
  //Avatar,
  Button,
  //CardHeader,
  Chip, Hidden,
  //IconButton,
  //Typography
} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearToken } from '../../store';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";
import styles from './Navbar.module.scss';
import { PrivateRoute } from '../PrivateRoute';
import KeyboardVoiceIcon from '@material-ui/icons/AccountCircle';
import Logout from '@material-ui/icons/ExitToApp';
import Login from '@material-ui/icons/Person';
import Schedule from '@material-ui/icons/Schedule';
import { Appbar } from './Appbar';
import * as Views from '../route';
import EcoIcon from "@material-ui/icons/Eco";
import numeral from 'numeral';

const DASHBOARD_ROUTES = [
  { label: 'Dashboard', path: "/dashboard", component: Views.Dashboard, navigatable: true }
  // Will add subrouting later
];

const WEBSITE_ROUTES = [
  { label: 'Home', path: "/", component: Views.Home, navigatable: false },
  { label: 'Why OffsetSpot', path: "/why", component: Views.Why, navigatable: true },
  { label: '', path: "/calculator/:code", component: Views.Calculator, navigatable: false },
  { label: 'Calculator', path: "/calculator", component: Views.Calculator, navigatable: true },
  { label: '', path: "/project/:name", component: Views.Project, navigatable: false },
  { label: 'Projects', path: "/project", component: Views.Projects, navigatable: true },
  { label: '', path: "/offsetting", component: Views.Offsetting, navigatable: false },
  { label: '', path: "/careers", component: Views.Careers, navigatable: false },
  { label: '', path: "/company/grossman-insurance", component: Views.Grossman, navigatable: false },
  { label: '', path: "/company/:name", component: Views.Company, navigatable: false },
  { label: '', path: "/demo", component: Views.Demo, navigatable: false },
  { label: '', path: "/terms", component: Views.Terms, navigatable: false },
  { label: '', path: "/privacy", component: Views.Privacy, navigatable: false },
  { label: '', path: "/signup", component: Views.Signup, navigatable: false },
  { label: '', path: "/login", component: Views.Login, navigatable: false },
  { label: '', path: "/contact", component: Views.Contact, navigatable: false },
  { label: '', path: "/about", component: Views.About, navigatable: false },
  { label: '', path: "/showcase", component: Views.Showcase, navigatable: false },
  { label: '', path: "/password_reset/:code", component: Views.Password, navigatable: false },
  { label: '', path: "/verify/:code", component: Views.Verify, navigatable: false },
  { label: '', path: "/please_verify", component: Views.PleaseVerify, navigatable: false },
];

type NavbarMenuProps = {
  label: string;
  items: any[];
  width: number;
}

/*
const NavbarMenu = (props: NavbarMenuProps)  => {
  const [anchor, setAnchor] = useState(null);

  const onMenuItemClick = (item: any) => item.onClick(item) || onMenuClose();
  const onMenuClose = () => setAnchor(null);
  const onMenuClick = (event: any) => {
    const {top, right} = event.currentTarget.getBoundingClientRect();
    setAnchor({ top,  left: right - props.width} as any);
  };

  const endIcon = <Icon className={styles.navbarDropIcon}>arrow_drop_down</Icon>;
  const menuButtonClasses = [styles.navbarLink, styles.navbarDropLink].join(' ');
  const menuLinkClasses = [styles.navbarLink, styles.navbarSubLink].join(' ');

  return (
    <React.Fragment>
      <Button
        onClick={e => onMenuClick(e)}
        className={menuButtonClasses}
        endIcon={endIcon}>
        {props.label}
      </Button>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchor as any}
        className={styles.navbarMenu}
        keepMounted
        open={Boolean(anchor)}
        onClose={() => onMenuClose()}>
        {props.items.map(link => <MenuItem key={link.text} onClick={() => onMenuItemClick(link)}>{link.text}</MenuItem>)}
      </Menu>
      {props.items.map(link => (<Button key={link.text} onClick={() => onMenuItemClick(link)} className={menuLinkClasses}>{link.text}</Button>))}
    </React.Fragment>
  );
};*/

type NavbarProps = RouteComponentProps & {
  userLoggedIn: boolean;
  userOffset: number;
  logout: () => void;
}

class Navbar extends Component<NavbarProps> {
  state = { drawerOpen: false };

  render() {
    const { drawerOpen } = this.state;
    const { userLoggedIn } = this.props;
    const onDrawOpen = () => this.setState({ drawerOpen: true });
    const onDrawClose = () => this.setState({ drawerOpen: false });
    const onIconClick = () => this.props.history.push('/');
    const navigateTo = (url: string) => {
      this.setState({ drawerOpen: false });
      this.props.history.push(url);
    };

    const userOnDashboard = DASHBOARD_ROUTES.map(route => route.path).includes(this.props.location.pathname);
    //const dashboardLinks = DASHBOARD_ROUTES.filter(route => route.navigatable);
    const websiteLinks = WEBSITE_ROUTES.filter(route => route.navigatable);
    /*
    const dropDownLinks = [
      {
        label: 'Resources',
        width: 205,
        items: [
          { text: 'About the Calculator', onClick: () => navigateTo('/about') },
          { text: 'What is Carbon Offsetting', onClick: () => navigateTo('/offsetting') }
        ]
      }
    ];*/

    const DashboardMenu =
      <React.Fragment>
        <Hidden xsDown>
          <Chip avatar={<EcoIcon style={{background:'none'}} />} color={'primary'} label={`${numeral(1000 * this.props.userOffset).format('0')}kg C02 offset`} />
        </Hidden>
        {/*dashboardLinks.map(route => <Button key={route.path} onClick={() => navigateTo(route.path)} className={styles.navbarLink}>{route.label}</Button>)*/}
        <Button endIcon={<Logout />} onClick={() => this.props.logout()} className={styles.navbarLink}>Logout</Button>
      </React.Fragment>;

    const WebsiteMenu =
      <React.Fragment>
        {
          websiteLinks.map((route, index) => {
            const classes = [styles.navbarLink, index === websiteLinks.length - 1 ? styles.lastNavbarLink : ''].join(' ');
            return <Button key={route.path} onClick={() => navigateTo(route.path)} className={classes}>{route.label}</Button>
          })
        }
        {
          userLoggedIn ?
            <Button startIcon={<KeyboardVoiceIcon />} disableElevation color={'primary'} variant={'contained'} onClick={() => navigateTo('/dashboard')} className={styles.accountLink}>My Account</Button> :
            <React.Fragment>
              <Button startIcon={<Login />} disableElevation color={'primary'} variant={'outlined'} onClick={() => navigateTo('/login')} className={styles.accountLink}>Login</Button>
              <Button startIcon={<Schedule />} disableElevation color={'primary'} variant={'contained'} onClick={() => navigateTo('/demo')} className={styles.accountLink}>Book Demo</Button>
            </React.Fragment>
        }
      </React.Fragment>;

    return (
      <React.Fragment>
        <Appbar onIconClick={onIconClick} onDrawOpen={onDrawOpen} onDrawClose={onDrawClose} drawerOpen={drawerOpen} >
          {userLoggedIn && userOnDashboard ? DashboardMenu : WebsiteMenu}
        </Appbar>
        <div className={styles.navbarPages}>
          <Switch>
            {DASHBOARD_ROUTES.map(route => <PrivateRoute key={route.path} authorised={userLoggedIn} path={route.path} component={route.component} />)}
            {WEBSITE_ROUTES.map(route => <Route key={route.path} exact={route.path === '/'} path={route.path} component={route.component} />)}
            <Route component={Views.Home}></Route>
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: any) {
  const userLoggedIn =  state.user.token !== null;
  const userOffset = userLoggedIn && state.user.subscription ? state.user.subscription.subscriptionFootprint.tonnes : 0;
  return {userLoggedIn, userOffset };
}

function mapDispatchToProps(dispatch: any) {
  return { logout: () => dispatch(clearToken()) };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
