
import UserService from '../../services/user';
import {SET_INPUTS} from "./calculator";

export const STORE_TOKEN = "STORE_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";
export const STORE_PROFILE = "STORE_PROFILE";
export const STORE_STATISTICS = "STORE_STATISTICS";
export const STORE_INVOICES = "STORE_INVOICES";
export const STORE_NEXT_INVOICE = "STORE_NEXT_INVOICE";
export const STORE_SUBSCRIPTION = "STORE_SUBSCRIPTION";
export const STORE_FOOTPRINT = "STORE_FOOTPRINT";

export const storeToken = (token: string, finished: () => void): any => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: STORE_TOKEN, data: token });
    UserService.fetchUserData(token, dispatch, () => finished());
  }
};

export const clearToken = () => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: STORE_TOKEN, data: null });
    UserService.logout();
  }
};

export const storeProfile = (profile: any) => {
  return { type: STORE_PROFILE, data: profile }
};

export const storeStatistics = (stats: any) => {
  return { type: STORE_STATISTICS, data: stats }
};

export const storeInvoices = (invoices: any) => {
  return { type: STORE_INVOICES, data: invoices }
};

export const storeNextInvoice = (nextInvoice: any) => {
  return { type: STORE_NEXT_INVOICE, data: nextInvoice }
};

export const storeFootprint = (response: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: SET_INPUTS, data: response.footprint.carbon_usage });
    dispatch({ type: STORE_FOOTPRINT, data: response.footprint });
  }
};

export const storeSubscription = (subscription: any) => {
  return { type: STORE_SUBSCRIPTION, data: subscription }
};
