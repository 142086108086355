/// <reference types="react-scripts" />
// Polyfills for IE11 support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';

render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);
