
import * as Actions from '../actions/calculator';
import { Action, CalculatorSection } from '../../types';
import { camelizeKeys } from '../../services/camelcase';

const calculator = (state: any = {}, action: Action) => {
  switch (action.type) {
    case Actions.SET_CALCULATION_CONFIG: {
      const steps = camelizeKeys(action.data) as CalculatorSection[];
      const unitArrayToMap = (obj: any, input: any) => {
        const { name, unit, value } = input;
        obj[name] = { unit, value, defaultValue: true, initialValue: value };
        return obj;
      };

      const inputsArray = steps.flatMap(step => {
        return step.components.map(component => {
          return {
            name: component.fieldName,
            unit: component.defaultValues[0].unitName,
            value: component.defaultValues[0].value
          }
        });
      });

      const inputs = inputsArray.reduce(unitArrayToMap, {});
      return Object.assign({}, state, { steps, inputs });
    }
    case Actions.SET_INPUTS: {
      const newState = Object.assign({}, state);
      const newValues = action.data;
      Object.keys(newState.inputs).forEach(key => {
        const newValue = newValues[key];
        const oldValue = state.inputs[key];
        state.inputs[key] = Object.assign(oldValue, newValue);
      });
      return newState;
    }
    case Actions.SET_OUTPUT: {
      const breakdownForAnalysis = Object.assign({}, action.data.breakdown_for_analysis);
      const breakdownForSpiderDiag = Object.assign({}, action.data.breakdown_for_spider_diag);
      const output: any = camelizeKeys(action.data);
      output.breakdownForAnalysis = breakdownForAnalysis;
      output.breakdownForSpiderDiag = breakdownForSpiderDiag;
      return Object.assign({}, state, { output });
    }
    case Actions.SET_CALCULATOR_FIELD_VALUE: {
      const { name, value } = action.data;
      state.inputs[name].defaultValue = value === state.inputs[name].initalValue;
      state.inputs[name].value = value;
      return Object.assign({}, state);
    }
    case Actions.SET_CALCULATOR_FIELD_UNIT: {
      const { name, unit, value } = action.data;
      state.inputs[name].defaultValue = true;
      state.inputs[name].initialValue = value;
      state.inputs[name].value = value;
      state.inputs[name].unit = unit;
      return Object.assign({}, state);
    }
    default:
      return state
  }
};

export default calculator
