import React, { Component } from 'react'
import {
  AppBar,
  Toolbar,
  Drawer,
  IconButton
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../assets/png/logo.png';
import styles from './Appbar.module.scss'

export type AppbarProps = {
  onIconClick: () => void;
  onDrawClose: () => void;
  onDrawOpen: () => void;
  drawerOpen: boolean;
};

export class Appbar extends Component<AppbarProps> {
  render() {
    return (
      <AppBar position="fixed" className={styles.navbar}>
        <Toolbar className={styles.toolbar}>
          <div onClick={() => this.props.onIconClick()} className={styles.title}>
            <img className={styles.logo} src={logo} alt='OffsetSpot Logo' />
          </div>
          <div className={styles.links}>
            {this.props.children}
          </div>
          <div className={styles.mobileLinks}>
            <IconButton onClick={() => this.props.onDrawOpen()} className={styles.menu}>
              <MenuIcon />
            </IconButton>
            <Drawer elevation={0} transitionDuration={{ enter: 0, exit: 0 }} anchor="top" open={this.props.drawerOpen} onClose={() => this.props.onDrawClose()}>
              <div className={styles.drawerLinks}>
                {this.props.children}
              </div>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
