
import { WithSnackbarProps, OptionsObject } from 'notistack';

export default {
  showSuccess(props: WithSnackbarProps, text: string) {
    const options: OptionsObject = { variant: 'success' };
    props.enqueueSnackbar(text, options);
  },

  showError(props: WithSnackbarProps, text: string) {
    const options: OptionsObject = { variant: 'error' };
    props.enqueueSnackbar(text, options)
  }
}
