
import { SubscribeResponse } from '../types';
import config from '../config';
import axios from 'axios';

type SubscribeFields = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  carbonUsage: any;
  subscribeCode: string;
  projectUid: string;
}

export default {
  createUserAndSubscribe(fields: SubscribeFields, callback: (token: string, error: string) => void) {
    const body = {
      first_name: fields.firstName,
      last_name: fields.lastName,
      email: fields.emailAddress,
      password: fields.password,
      project_uid: fields.projectUid,
      carbon_usage: fields.carbonUsage,
      employer_subscribe_code: fields.subscribeCode,
    };

    const handleResponse = (response: SubscribeResponse | null, error: any) => {
      if (response !== null) {
        callback(response.auth_token, response.message);
      } else {
        console.error(response, error);
      }
    }

    axios.post(`${config.baseUrl}/subscription/create_user_save_footprint_subscribe`, body)
      .then(resp => handleResponse(resp.data, null))
      .catch(error => handleResponse(null, error))
  }
}
