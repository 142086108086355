
import { WithSnackbarProps } from 'notistack';
import toastrService from './toast';
import axios from 'axios';
import config from '../config';

export default {
  scheduleDemo(props: WithSnackbarProps, body: any, clearState: () => void) {
    const handleResponse = (success: boolean) => {
      if (success) {
        toastrService.showSuccess(props, 'Schedule demo request sent successfully');
        clearState();
      } else {
        toastrService.showError(props, 'Schedule demo request failed to send...');
      }
    };

    axios.post(`${config.baseUrl}/schedule_demo`, body)
      .then(resp => handleResponse(resp.data.error === 0))
      .catch(() => handleResponse(false))
  },

  contactUs(props: WithSnackbarProps, body: any, clearState: () => void) {
    const handleResponse = (success: boolean) => {
      if (success) {
        toastrService.showSuccess(props, 'Thanks! We aim to respond to your enquiry within one working day.');
        clearState();
      } else {
        toastrService.showError(props, 'We failed to send your enquiry, please try contacting us over the phone.');
      }
    }

    axios.post(`${config.baseUrl}/contact`, body)
      .then(resp => handleResponse(resp.data.error === 0))
      .catch(() => handleResponse(false))
  }
}
