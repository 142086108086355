
import { createHelmetRoute } from '../HelmetRoute';
import { createLazyRoute } from '../LazyRoute';


// Key routes with additional metadata for seo
export const Home = createHelmetRoute({
  title: 'OffsetSpot | Offset your employees\' carbon footprints',
  description: 'Join OffsetSpot and offset your employees\' carbon emissions as part of your business\' benefits scheme. Make corporate sustainability work for you.',
  url: 'https://offsetspot.com/',
  loader: () => import('./Home')
});

export const Why = createHelmetRoute({
  title: 'OffsetSpot | Why offset?',
  description: 'Why offset with OffsetSpot? Do good, engage your staff, improve your reputation and see a threefold return on investment.',
  url: 'https://offsetspot.com/why',
  loader: () => import('./Why')
});

export const Demo = createHelmetRoute({
  title: 'OffsetSpot | Book a demo',
  description: 'Get in touch to schedule a demo for your business. OffsetSpot offers demos across the UK and Europe.',
  url: 'https://offsetspot.com/demo',
  loader: () => import('./Demo')
});


export const Calculator = createHelmetRoute({
  title: 'OffsetSpot | Calculate your footprint',
  description: 'Try calculating your carbon footprint with the OffsetSpot calculator.',
  url: 'https://offsetspot.com/calculator',
  loader: () => import('./Calculator')
});

export const Projects = createHelmetRoute({
  title: 'OffsetSpot | World Changing projects',
  description: 'Every OffsetSpot project has been awarded the Gold Standard certification, an independent benchmark and the only effective guarantee that an offset project will support real emission reductions.',
  url: 'https://offsetspot.com/project',
  loader: () => import('./Projects')
});

export const Login = createHelmetRoute({
  title: 'OffsetSpot | Login to your account',
  description: 'Login to your OffsetSpot account to view or amend your carbon footprint and see your positive impact.',
  url: 'https://offsetspot.com/login',
  loader: () => import('./Login')
});

// Other Routes
export const Dashboard = createLazyRoute(() => import('./Dashboard'));
export const Offsetting = createLazyRoute(() => import('./Offsetting'));
export const Password = createLazyRoute(() => import('./Password'));
export const Contact = createLazyRoute(() => import('./Contact'));
export const Careers = createLazyRoute(() => import('./Careers'));
export const Project = createLazyRoute(() => import('./Project'));
export const Privacy = createLazyRoute(() => import('./Privacy'));
export const Company = createLazyRoute(() => import('./Company'));
export const Grossman = createLazyRoute(() => import('./Grossman'));
export const Verify = createLazyRoute(() => import('./Verify'));
export const PleaseVerify = createLazyRoute(() => import('./PleaseVerify'));
export const Signup = createLazyRoute(() => import('./Signup'));
export const Terms = createLazyRoute(() => import('./Terms'));
export const About = createLazyRoute(() => import('./About'));
export const Showcase = createLazyRoute(() => import('./Showcase'));
