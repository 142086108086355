
import { Action } from '../../types';
import * as Actions from '../actions/register';
import  {camelizeKeys } from "../../services/camelcase";

const register = (state: any = {}, action: Action) => {
  switch(action.type) {
    case Actions.SET_SUBSCRIPTION: {
      const newState = Object.assign({}, state);
      newState.subscription = action.data;
      return newState;
    }
    case Actions.SET_EMPLOYER: {
      const newState = Object.assign({}, state);
      newState.employer = camelizeKeys(action.data);
      return newState;
    }
  }

  return state;
}

export default register;
