
import { WithSnackbarProps } from 'notistack';
import toastr from './toast';
import { setEmployer } from '../store';
import { dispatch } from '../store';
import axios from 'axios';
import config from '../config';
import {camelizeKeys} from "./camelcase";

export default {
  fetchEmployerByCode(component: WithSnackbarProps, code: string, callback: () => void) {
    const handleResponse = (response: any) => {
      if(response.error === 0) {
        toastr.showSuccess(component, `${response.employer.human_name} ${response.employer.inline_perk_description}`);
        dispatch(setEmployer(response.employer));
        callback();
      } else {
        toastr.showError(component, 'Sorry, we dont recognise that employer code...')
      }
    };

    axios.post(`${config.baseUrl}/employer/by_subscribe_code`, { employer_subscribe_code: code })
      .then(resp => handleResponse(resp.data))
      .catch(() => handleResponse({ error: -1 }))
  },

  fetchEmployerByName(name: string, callback: (data: any | null) => void) {
    const body = { page_name: name };
    const requests = [
      axios.post(`${config.baseUrl}/employer/by_page_name`, body),
      axios.post(`${config.baseUrl}/stats/employer`, body),
    ];

    const onError = (errors: any[], callback: (data: any | null) => void) => {
      console.error(errors);
      callback(null)
    };

    axios.all(requests).then(axios.spread((...responses) => {
      if (responses.every((response: any) => response.data.error === 0)) {
        const employer = camelizeKeys(responses[0].data.employer);
        const stats = camelizeKeys(responses[1].data);
        delete stats['error'];
        callback({ employer, stats });
      } else {
        onError(responses, callback)
      }
    })).catch(errors => onError(errors, callback));
  },
}
