
import { calculateFootprint } from "./calculator";

export const SET_SUBSCRIPTION = "SELECT_SUBSCRIPTION";
export const SET_EMPLOYER = 'SET_EMPLOYER';

export const setSubscription = (project: any): any => {
  return {
    type: SET_SUBSCRIPTION,
    data: project
  }
};

export const setEmployer = (employer: any): any => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: SET_SUBSCRIPTION, data: null });
    dispatch(calculateFootprint({ type: SET_EMPLOYER, data: employer }));
  }
};

